/* eslint-disable no-unused-vars */
import { lazy } from "react";
import { Navigate } from "react-router-dom";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

import sessionRoutes from "./views/sessions/session-routes";
import materialRoutes from "app/views/material-kit/MaterialRoutes";

// E-CHART PAGE
const AppEchart = Loadable(lazy(() => import("app/views/charts/echarts/AppEchart")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
//login
const Login = Loadable(lazy(() => import("app/views/sessions/login/FirebaseLogin")));

//home
const Home = Loadable(lazy(() => import("app/Home/pages/demo")));
//academicYear
const Aacademic = Loadable(lazy(() => import("app/views/master/academicYear/academic")));
//college
const College = Loadable(lazy(() => import("app/views/master/colleges/college")));
//courses
const Courses = Loadable(lazy(() => import("app/views/master/courses/course")));
//courses
const Coursestype = Loadable(lazy(() => import("app/views/master/courseType/coursetype")));
//courses
const Credithours = Loadable(lazy(() => import("app/views/master/creditHours/credit")));
//degree
const Degrees = Loadable(lazy(() => import("app/views/master/degree/degree")));
//department
const Department = Loadable(lazy(() => import("app/views/master/department/department")));
//designation
const Designation = Loadable(lazy(() => import("app/views/master/designation/designation")));
//bactch
const Batch = Loadable(lazy(() => import("app/views/master/batch/batchs")));
//programme
const Programme = Loadable(lazy(() => import("app/views/master/programme/programmes")));
//programmeyear
const Programmeyear = Loadable(lazy(() => import("app/views/master/programmeYear/programmeyear")));
//semester
const Semester = Loadable(lazy(() => import("app/views/master/semester/semester")));
//uploadDataCourse
const Coursedata = Loadable(lazy(() => import("app/views/master/uploadDataCourse/coursedata")));



//user
const User = Loadable(lazy(() => import("app/views/admin/user/user")));
//user
const Modulefeature = Loadable(lazy(() => import("app/views/admin/moduleFeature/moduleFeaturemap")));
//user
const Rolesfeature = Loadable(lazy(() => import("app/views/admin/rolesFeature/rolesFeaturemap")));








//Exam Announcement
const Examannouncement = Loadable(
  lazy(() => import("app/views/preExam/examAnnouncement/examAnnouncement"))
);
//Barcode
const Barcode = Loadable(lazy(() => import("app/views/preExam/barcode/barcode")));
//Evaluator Selection
const Evaluator = Loadable(
  lazy(() => import("app/views/postExam/evaluatorSelection/evaluatorSection"))
);
//Booklet Upload
const Booklet = Loadable(lazy(() => import("app/views/postExam/bookletUpload/bookletUpload")));
//Booklet Allocation
const Bookletallocation = Loadable(
  lazy(() => import("app/views/postExam/bookletAllocation/bookletAllocation"))
);
//Booklet Re-Allocation
const Bookletreallocation = Loadable(
  lazy(() => import("app/views/postExam/bookletReallocation/bookletReallocation"))
);
//QP And Answer Keys
const QPandAnswerkeys = Loadable(lazy(() => import("app/views/postExam/QpanswerKeys/QpanswerKey")));
//Booklet Evaluation status Report
const BookletevaluationStatus = Loadable(
  lazy(() => import("app/views/postExam/bookletEvaluationstatus/bookletEvaluationstatus"))
);
//Result Publish
const Resultpublish = Loadable(
  lazy(() => import("app/views/postExam/resultPublish/resultPublish"))
);
//Evaluation report
const Evaluationreport = Loadable(
  lazy(() => import("app/views/reports/evaluation/evaluation.jsx"))
);
//result report
const Resultreport = Loadable(lazy(() => import("app/views/reports/results/result.jsx")));
//other report
const Otherreport = Loadable(lazy(() => import("app/views/reports/others/other.jsx")));
//Re-Evaluation report
const ReevaluationReport = Loadable(lazy(() => import("app/views/reports/Re-evaluation/reEvaluationreport")));
//onlinecourse
const OnlineCourseRegistration = Loadable(
  lazy(() => import("app/views/utility/offlineCourseregistrations/offlinecourse.jsx"))
);
//Releasebooklet
const ReleaseBooklet = Loadable(
  lazy(() => import("app/views/utility/releaseBooklets/releaseBooklet.jsx"))
);
//Updatecourse Details
const UpdateCoursedetails = Loadable(
  lazy(() => import("app/views/utility/updateCoursedetail/updateCoursedetails.jsx"))
);
//Retrive Booklet
const RetriveBooklet = Loadable(
  lazy(() => import("app/views/utility/retriveBooklets/retriveBooklet.jsx"))
);
//Upload Data Re-Evaluation
const UploadDataRe = Loadable(
  lazy(() => import("app/views/Re-Evaluation/uploadData/uploadData.jsx"))
);
//BookletAllocation Re-Evaluation
const BookletallocationRe = Loadable(
  lazy(() => import("app/views/Re-Evaluation/bookletAllocation/bookletAllocation.jsx"))
);
//Booklet Re-Allocation Re-Evaluation
const BookletReallocationRe = Loadable(
  lazy(() => import("app/views/Re-Evaluation/bookletReallocation/bookletReallocation.jsx"))
);
//Booklet Re-Allocation status Re-Evaluation
const ReEvaluationdetailsStatus = Loadable(
  lazy(() =>
    import("app/views/Re-Evaluation/reEvaluationdetailsStatus/reEvaluationdetailsStatus.jsx")
  )
);
//Booklet Evaluation status Re-Evaluation
const BookletReevaluationRe = Loadable(
  lazy(() =>
    import("app/views/Re-Evaluation/bookletreEvaluationstatus/bookletreEvaluationstatus.jsx")
  )
);
const routes = [
  {
    path: "/",
    element: <Home />
  },

  {
    path: "/auth/login",
    element: <Login />
  },

  {
    element: (
      <>
        <MatxLayout />
      </>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: "/dashboard",
        element: <Analytics />
      },
      // e-chart route
      {
        path: "/charts/echarts",
        element: <AppEchart />
      },


      // User route
      {
        path: "/admin/user",
        element: <User />
      },
       // Module Feature Mapping route
       {
        path: "/admin/moduleFeaturemapping",
        element: <Modulefeature />
      },
       // Roles Feature Mapping route
       {
        path: "/admin/rolesFeaturemapping",
        element: <Rolesfeature  />
      },



      // Degree route
      {
        path: "/master/degree",
        element: <Degrees />
      },
      //college route
      {
        path: "master/colleges",
        element: <College />
      },
      //academic year route
      {
        path: "/master/academicYear",
        element: <Aacademic />
      },
      //courses route
      {
        path: "/master/courses",
        element: <Courses />
      },
      //Batch route
      {
        path: "/master/batch",
        element: <Batch />
      },
      //courseType route
      {
        path: "/master/courseType",
        element: <Coursestype />
      },
      //creditHours route
      {
        path: "/master/creditHours",
        element: <Credithours />
      },
      //department route
      {
        path: "/master/department",
        element: <Department />
      },
      //designation route
      {
        path: "/master/designation",
        element: <Designation />
      },
      //programme route
      {
        path: "/master/programme",
        element: <Programme />
      },
      //programmeYear route
      {
        path: "/master/programmeYear",
        element: <Programmeyear />
      },
      //semester route
      {
        path: "/master/semester",
        element: <Semester />
      },
      //uploadDataCourse
      {
        path: "/master/courseData",
        element: <Coursedata />
      },

      //Examination Announcement
      {
        path: "/preExam/examAnnouncement",
        element: <Examannouncement />
      },
      //Barcode
      {
        path: "/preExam/barCode",
        element: <Barcode />
      },
      //Evaluator Selection
      {
        path: "/postExam/evaluatorSelection",
        element: <Evaluator />
      },
      //Booklet Upload
      {
        path: "/postExam/bookletUpload",
        element: <Booklet />
      },
      //Booklet Allocation
      {
        path: "/postExam/bookletAllocation",
        element: <Bookletallocation />
      },
      //Booklet Re-allocation
      {
        path: "/postExam/bookletReallocation",
        element: <Bookletreallocation />
      },
      //QPandAnswerKey
      {
        path: "/postExam/QPandAnswerkey",
        element: <QPandAnswerkeys />
      },
      //Booklet Evaluation status
      {
        path: "/postExam/BookletEvaluationstatus",
        element: <BookletevaluationStatus />
      },
      //Result Publish
      {
        path: "/postExam/ResultPublish",
        element: <Resultpublish />
      },
      //Evaluation Report
      {
        path: "/report/Evaluation",
        element: <Evaluationreport />
      },
      //Result Report
      {
        path: "/report/Result",
        element: <Resultreport />
      },
      //other Report
      {
        path: "/report/Other",
        element: <Otherreport />
      },
      //Re-Evaluation Report
      {
        path:"/report/Re-evaluation",
        element:<ReevaluationReport/>
      },
      //online Course Registration
      {
        path: "/utility/offlineCourseregistration",
        element: <OnlineCourseRegistration />
      },
      {
        path: "/utility/releaseBooklet",
        element: <ReleaseBooklet />
      },
      {
        path: "/utility/updateCoursedetails",
        element: <UpdateCoursedetails />
      },
      {
        path: "/utility/retriveBooklet",
        element: <RetriveBooklet />
      },
      //Re-Evaluation
      {
        path: "/Re-Evaluation/uploadData",
        element: <UploadDataRe />
      },
      {
        path: "/Re-Evaluation/bookletAllocation",
        element: <BookletallocationRe />
      },
      {
        path: "/Re-Evaluation/bookletReallocation",
        element: <BookletReallocationRe />
      },
      {
        path: "/Re-Evaluation/reEvaluationdetailsStatus",
        element: <ReEvaluationdetailsStatus/>
      },
      {
        path: "/Re-Evaluation/bookletreEvaluationstatus",
        element: <BookletReevaluationRe />
      }
    ]
  },

  // session pages route
  ...sessionRoutes
];

export default routes;
